
import { defineComponent, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { MenuComponent } from "@/assets/ts/components";
import MainMenuConfig from "@/core/config/Module";
import { headerMenuIcons } from "@/core/helpers/config";
import { version } from "@/core/helpers/documentation";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "KTMenu",
  components: {},
  data() {
    return {
      MainMenu: MainMenuConfig,
    };
  },
  async created() {
    //await this.TopVar();
  },
  methods: {
    async TopVar() {
      const store = useStore();
      console.log(this.MainMenu);
      //console.log(this.MainMenuConfig[0].pages);
      if (store.getters.currentUser.id === 1) {
        return;
      } else {
        ApiService.get("entityorganogram/info/" + store.getters.currentUser.id)
          .then(({ data }) => {
            console.log(data);
            this.MainMenu[1].heading = "";
           
            let menu = {
              heading: "",
              route: "",
              fontIcon: "",
              svgIcon: "",
            };
            let dataMenu =
              data.data[0].entity_type_role.menu_action_permissions;
            for (let i = 0; i < dataMenu.length; i++) {
              if (dataMenu[i].menu.category === "top") {
                menu.heading = dataMenu[i].menu.heading;
                menu.route = dataMenu[i].menu.link;
                menu.fontIcon = dataMenu[i].menu.icon;
                //this.MainMenu.push(menu);
              }
            }
            console.log(this.MainMenu);

            // menu.heading =
            //   data.data[0].entity_type_role.menu_action_permissions[0].menu.heading;
            // menu.route =
            //   data.data[0].entity_type_role.menu_action_permissions[0].menu.link;
            // menu.fontIcon =
            //   data.data[0].entity_type_role.menu_action_permissions[0].menu.icon;
            // this.MainMenu[0].pages.push(menu);
          })
          .catch(({ response }) => {
            console.log(response);
          });
      }
    },
  },
  setup() {
    const store = useStore();
    const { t, te } = useI18n();
    const route = useRoute();

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };
    console.log(store.getters.currentUser.id);
    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    onMounted(() => {
      MenuComponent.reinitialization();
    });

    return {
      hasActiveChildren,
      headerMenuIcons,
      MainMenuConfig,
      translate,
      version,
    };
  },
});
