const Module = [
  {
    pages: [
      {
        heading: "Dashboard",
        route: "/dash",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Entity",
        route: "/association",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Training Institute",
        route: "/training-institute",
        svgIcon: "media/icons/duotune/art/art002.svg",
      },
    ],
  },
  
  {
    heading: "Course",
    pages: [
      {
        heading: "Course",
        route: "/course",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-layers",
      },
      {
        heading: "Course Batch",
        route: "/course-batch",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-layers",
      },
    ],
  },
  {
    heading: "Training",
    pages: [
      {
        heading: "Trainer",
        route: "/trainer",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Assessor",
        route: "/assessor",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Trainee",
        route: "/trainee",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Training",
        route: "/training",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Assessment",
        route: "/assessment",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Certification",
        route: "/certificate",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Job Placement",
        route: "/job-placement",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Enrollment",
        route: "/enrollment",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "Training Program",
        route: "/training-program-list",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    heading: "Billing",
    pages: [
      {
        heading: "Training Partners Info",
        route: "/training-partners-info",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
      {
        heading: "SEIP Bill Info",
        route: "/seip-bill-info",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  // {
  //   pages: [
  //     {
  //       heading: "Module 3",
  //       route: "/crafted/pages/profile/overview",
  //       svgIcon: "media/icons/duotune/general/gen019.svg",
  //       fontIcon: "bi-layers",
  //     },
  //     {
  //       heading: "Module 4",
  //       route: "/crafted/pages/profile/projects",
  //       svgIcon: "media/icons/duotune/general/gen019.svg",
  //       fontIcon: "bi-layers",
  //     },
  //   ],
  // },
  {
    heading: "Configuration",
    route: "/configuration",
    pages: [
      {
        heading: "Employee",
        route: "/employee",
        svgIcon: "media/icons/duotune/art/art002.svg",
      },
      {
        heading: "Geo",
        route: "/geo",
        svgIcon: "media/icons/duotune/art/art002.svg",
      },
      {
        heading: "General Settings",
        route: "/settings",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-archive",
      },
      {
        heading: "Entities",
        route: "/entities",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-archive",
      },
      {
        heading: "Course Settings",
        route: "/course-settings",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-archive",
      },
      {
        heading: "Training Settings",
        route: "/training-settings",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-archive",
      },
      {
        heading: "Training Program Settings",
        route: "/training-program",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-archive",
      },
      {
        heading: "Entity Organogram",
        route: "/entity-organogram",
        svgIcon: "media/icons/duotune/general/gen019.svg",
        fontIcon: "bi-archive",
      },
    ],
  },
  {
    heading: "Others",
    route: "/others",
    pages: [
      {
        heading: "Stipend",
        route: "/stipend",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
      {
        heading: "Field Visit",
        route: "/fieldvisit",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
      {
        heading: "Reporting",
        route: "/report",
        svgIcon: "media/icons/duotune/general/gen022.svg",
        fontIcon: "bi-archive",
      },
    ],
  },
];

export default Module;
